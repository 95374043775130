<template>
  <div class="login-page">
    <Head title="注册" />
    <Warpper top="40">
      <img class="pic" src="../../assets/images/reg.png" alt />
      <div class="form-box">
        <FormItem type="tel" icon="user" v-model="tel" placeholder="请输入您的手机号"></FormItem>
        <FormItem type="password" icon="password" v-model="password" placeholder="请输入您的登录密码"></FormItem>
        <FormItem type="password" icon="password" v-model="password2" placeholder="请再次输入您的登录密码"></FormItem>
        <div class="rule-box">
          <mt-switch class="rule-switch" v-model="rule"></mt-switch>
          <span>
            同意《
            <a @click="toRule">拍卖规则</a>》
          </span>
        </div>
        <button class="login-btn" @click="onSubmit">注册</button>
        <div class="link-info">
          <span>
            已有账号？
            <router-link to="/login">前往登录/绑定</router-link>
          </span>
        </div>
      </div>
    </Warpper>
  </div>
</template>

<script>
import FormItem from "components/FormItem";
import { reqRegister,reqRulePath } from "@/network/api";
export default {
  data() {
    return {
      tel: "",
      password: "",
      password2: "",
      rule: true,
      rulePath:reqRulePath
    };
  },
  components: {
    FormItem
  },
  created() {
    // this.$toast({
    //   message:"123"
    // })
  },
  mounted() {},
  methods: {
    toRule(){
      this.$router.push({ path: "/rule" });
    },
    onSubmit() {
      if (this.tel == "") {
        return this.$toast({
          message: "请输入您的手机号"
        });
      }
      if (!/^1\d{10}$/.test(this.tel)) {
        return this.$toast({
          message: "手机号有误，请重新填写",
        });
      }
      if (this.password == "") {
        return this.$toast({
          message: "请输入您的登录密码"
        });
      }
      if (this.password2 != this.password) {
        return this.$toast({
          message: "两次密码输入不一致"
        });
      }
      // 调用注册接口
      reqRegister({
        username: this.tel,
        password: this.password,
        openid:this.$store.getters.openid,
        referrer:this.$store.getters.mreferrer
      }).then(async res => {   
        const { msg, backdata,code } = res;
        this.$toast({message:msg});
        if(code!=0) return;
        await this.$store.dispatch('user/setToken', backdata.token);
        this.$router.replace({
          name:"login"
        })
      }).catch (error=>{
        this.$toast({message:'系统错误'});
      })
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.login-page {
  position: relative;
  width: 100%;
  background: #ffffff;
  .pic {
    width: 100%;
    height: 1.29rem;
    object-fit: cover;
    margin-bottom: 0.6rem;
  }
}
.form-box {
  width: 6.3rem;
  margin: 0 auto;
  .login-btn {
    width: 100%;
    height: 0.88rem;
    background: linear-gradient(0deg, #328f96 0%, #69c4b8 100%);
    border-radius: 0.44rem;
    margin-bottom: 0.52rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    border: 0;
  }
  button:focus {
    outline: 0;
  }
}
.link-info {
  text-align: center;
  font-size: 0.28rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin: 0 auto 0.2rem;
  a {
    font-size: 0.28rem;
    font-weight: 400;
    color: #359298;
  }
}
.rule-box {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 0.62rem;
  padding-left: 0.16rem;
  .rule-switch {
    margin-right: 0.16rem;
  }
  span {
    font-size: 0.24rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    a {
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #5fc68e;
    }
  }
}
</style>
