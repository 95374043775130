<template>
  <div class="input-item">
    <div class="left">
      <img v-if="icon=='user'" src="../assets/images/login_icon_account_nor.png" alt srcset />
      <img v-if="icon=='password'" src="../assets/images/login_icon_password_nor.png" alt srcset />
      <img v-if="icon=='code'" src="../assets/images/login_icon_verification_nor.png" alt srcset />
      <slot name="left"></slot>
    </div>
    <input :type="type" :value="value" :placeholder="placeholder" @input="input" />
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  name: "FormItem",
  data() {
    return {

    };
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      defalut: ""
    }
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    input() {
      this.$emit("input", event.target.value);
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.input-item {
  width: 6.3rem;
  height: 0.88rem;
  border: 0.02rem solid #dfdfdf;
  border-radius: 0.44rem;
  margin: 0 auto 0.3rem;
  box-sizing: border-box;
  padding-left: 1.12rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 0.4rem;
  position: relative;
  .left {
    position: absolute;
    left: 0.45rem;
    top: 50%;
    height: 0.44rem;
    margin-top: -0.22rem;
    width: 0.44rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  input {
    flex: 1;
    height: 100%;
    outline-color: transparent;
    border: 0;
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    &::-webkit-input-placeholder {
      font-size: 0.26rem;
      color: #999999;
    }
  }
}
</style>